const ActionTypes = {
   SET_STUDENTS: 'SET_STUDENTS',
   SET_CATEGORY: "SET_CATEGORY",
   ADD_CATEGORY: "ADD_CATEGORY",
   DELETE_CATEGORY: "DELETE_CATEGORY",
   MODIFY_CATEGORY: "MODIFY_CATEGORY",
   SET_QUESTIONS:"SET_QUESTIONS",
   ADD_QUESTION:"ADD_QUESTIONS",
   DELETE_QUESTION:"DELETE_QUESTIONS",
   MODIFY_QUESTION:"MODIFY_QUESTIONS",
   SET_RESULT: "SET_RESULT"
}

export default ActionTypes;
